const colors = [
  'case',
  ['==', ['get', 'user_color'], '#1FBC9C'],
  '#1FBC9C',
  ['==', ['get', 'user_color'], '#1CA085'],
  '#1CA085',
  ['==', ['get', 'user_color'], '#2ECC70'],
  '#2ECC70',
  ['==', ['get', 'user_color'], '#27AF60'],
  '#27AF60',
  ['==', ['get', 'user_color'], '#3398DB'],
  '#3398DB',
  ['==', ['get', 'user_color'], '#2980B9'],
  '#2980B9',
  ['==', ['get', 'user_color'], '#A463BF'],
  '#A463BF',
  ['==', ['get', 'user_color'], '#8E43AD'],
  '#8E43AD',
  ['==', ['get', 'user_color'], '#3D556E'],
  '#3D556E',
  ['==', ['get', 'user_color'], '#222F3D'],
  '#222F3D',
  ['==', ['get', 'user_color'], '#F2C511'],
  '#F2C511',
  ['==', ['get', 'user_color'], '#F39C19'],
  '#F39C19',
  ['==', ['get', 'user_color'], '#E84B3C'],
  '#E84B3C',
  ['==', ['get', 'user_color'], '#C0382B'],
  '#C0382B',
  ['==', ['get', 'user_color'], '#DDE6E8'],
  '#DDE6E8',
  ['==', ['get', 'user_color'], '#BDC3C8'],
  '#BDC3C8',
  ['==', ['get', 'user_mode'], 'snap-boundary'],
  '#fbb03b',
  ['==', ['get', 'user_mode'], 'driveway'],
  '#808080',
  ['==', ['get', 'user_mode'], 'easement'],
  '#ff0000',
  ['==', ['get', 'user_control_line'], 1],
  '#fdd005'
];
export default [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'fill-color': colors.concat('#3bb2d0'),
      'fill-outline-color': colors.concat('#3bb2d0'),
      'fill-opacity': 0.2
    }
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': colors.concat('#fbb03b'),
      'fill-outline-color': colors.concat('#3bb2d0'),
      'fill-opacity': 0.5
    }
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b'
    }
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': colors.concat('#3bb2d0'),
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': colors.concat('#3bb2d0'),
      'line-width': [
        'case',
        ['==', ['get', 'user_showGlDrawLineInactive'], false],
        0,
        ['==', ['get', 'user_boundry'], 1],
        0,
        ['!=', ['get', 'user_boundry'], 1],
        2,
        2
      ]
    }
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': [
        'case',
        ['==', ['get', 'user_showGlDrawLineActive'], false],
        1,
        ['==', ['get', 'user_boundry'], 1],
        0.2,
        ['!=', ['get', 'user_boundry'], 1],
        2,
        2
      ]
    }
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b'
    }
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#3bb2d0'
    }
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint']
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true']
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fbb03b'
    }
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1
    }
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040'
    }
  },
  {
    id: 'gl-draw-line-rotate-point',
    type: 'line',
    filter: [
      'all',
      ['==', 'meta', 'midpoint'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
      // ['==', 'active', 'true']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-polygon-rotate-point-stroke',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'midpoint'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 4,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-polygon-rotate-point',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'midpoint'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 2,
      'circle-color': '#fbb03b'
    }
  },
  // {
  //   id: 'gl-draw-polygon-rotate-point-icon',
  //   type: 'symbol',
  //   filter: ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
  //   layout: {
  //     'icon-image': 'rotate',
  //     'icon-allow-overlap': true,
  //     'icon-ignore-placement': true,
  //     'icon-rotation-alignment': 'map',
  //     'icon-rotate': ['get', 'heading']
  //   },
  //   paint: {
  //     'icon-opacity': 1.0,
  //     'icon-opacity-transition': {
  //       delay: 0,
  //       duration: 0
  //     }
  //   }
  // },
  {
    id: 'gl-draw-polygon-and-line-vertex-scale-icon',
    type: 'symbol',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
      ['has', 'heading']
    ],
    layout: {
      'icon-image': 'scale',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading']
    },
    paint: {
      'icon-opacity': 1.0,
      'icon-opacity-transition': {
        delay: 0,
        duration: 0
      }
    }
  }
];
